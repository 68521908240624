<template>
  <div>
    <div
      v-if="!addFontSize"
      class="d-flex sizeSelectsSettings"
      v-bind:class="{ allSizes: enable_height && enable_width }"
    >
      <new-form-group :label="height + ':'" class="mb-0">
        <multiselect
          label="label"
          :allow-empty="false"
          v-model="selectedHeight"
          :options="optionsSize"
          :searchable="false"
          :close-on-select="true"
          :show-labels="false"
          placeholder="Pick a value"
          @select="emitHeight"
        ></multiselect>
      </new-form-group>
    </div>
    <div
      v-else
      class="d-flex mb-4 sizeSelectsSettings"
      v-bind:class="{ allSizes: enable_height && enable_width }"
    >
      <new-form-group :label="height + ':'">
        <div class="d-flex fontSizes_input">
          <multiselect
            label="label"
            :allow-empty="false"
            v-model="selectedHeight"
            :options="optionsSize"
            :searchable="false"
            :close-on-select="true"
            :show-labels="false"
            placeholder="Pick a value"
            @select="emitHeight"
          ></multiselect>
          <slot></slot>
        </div>
      </new-form-group>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import NewFormGroup from '../Components/NewFormGroup'

export default {
  components: {
    Multiselect: () => import('vue-multiselect'),
    NewFormGroup
  },
  props: {
    enable_height: {
      type: Boolean,
      required: true
    },
    addFontSize: {
      type: Boolean,
      default: false
    },
    enable_width: {
      type: Boolean,
      required: false,
      default: false
    },
    messageSize: {
      type: Boolean,
      required: false,
      default: false
    },
    bubbleSize: {
      type: Boolean,
      required: false,
      default: false
    },
    heightE: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      heightSave: '',
      selectedHeight: '',
      selectedWidth: '',
      optionsSize: []
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    height() {
      if (this.bubbleSize) {
        return this.lang.botMaker.addBot.widget.bubbleSize[
          this.languageSelected
        ]
      } else if (this.messageSize) {
        return this.lang.botMaker.addBot.widget.messageSize[
          this.languageSelected
        ]
      } else {
        return this.lang.botMaker.botEditor.messageType.RcsCard.size_height[
          this.languageSelected
        ]
      }
    },
    width() {
      return this.lang.botMaker.botEditor.messageType.RcsCard.size_width[
        this.languageSelected
      ]
    }
  },
  methods: {
    emitHeight(value) {
      this.$emit('height', value.value)
    },
    emitWidth() {
      this.$emit('width', this.selectedWidth)
    },
    loadHeight(height) {
      this.heightSave = height + ''
      this.selectedHeight = this.getHeight(height)
    },
    clean() {
      if (this.heightSave) {
        this.selectedHeight = this.getHeight(this.heightSave)
      } else {
        this.selectedHeight = this.getMedium()
      }
    },
    getHeight(value) {
      return this.optionsSize.find(el => {
        return el.value == value
      })
    },
    erase() {
      this.selectedHeight = this.getMedium()
      this.$emit('height', this.selectedHeight.value)
    },
    getMedium() {
      return this.optionsSize.find(el => {
        return el.value == 'MEDIUM'
      })
    }
  },
  created() {
    if (this.bubbleSize) {
      this.optionsSize = [
        {
          label: this.lang.botMaker.addBot.widget.large[this.languageSelected],
          value: 'LARGE',
          isSelected: false
        },
        // {
        //   label: this.lang.botMaker.botEditor.messageType.RcsCard.sizes_medium[
        //     this.languageSelected
        //   ],
        //   value: 'MEDIUM',
        //   isSelected: false
        // },
        {
          label: this.lang.botMaker.addBot.widget.small[this.languageSelected],
          value: 'SMALL',
          isSelected: false
        }
      ]
    } else if (this.messageSize) {
      this.optionsSize = [
        {
          label: this.lang.botMaker.addBot.widget.large_width[
            this.languageSelected
          ],
          value: 'LARGE',
          isSelected: false
        },
        {
          label: this.lang.botMaker.addBot.widget.medium[this.languageSelected],
          value: 'MEDIUM',
          isSelected: false
        }
      ]
    } else {
      this.optionsSize = [
        {
          label: this.lang.botMaker.botEditor.messageType.RcsCard.sizes_medium[
            this.languageSelected
          ],
          value: 'MEDIUM',
          isSelected: false
        },
        {
          label: this.lang.botMaker.botEditor.messageType.RcsCard.sizes_short[
            this.languageSelected
          ],
          value: 'SMALL',
          isSelected: false
        }
      ]
    }
    if (this.heightE) {
      this.loadHeight(this.heightE)
    } else {
      this.erase()
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.sizeSelectsSettings {
  .form-group-label {
    align-items: center;
    display: flex;
  }
  &.allSizes {
    justify-content: space-between;
    max-width: 350px;
    margin: auto;
    .con-select.selectSize {
      width: 45%;
    }
  }
}

.w-100 {
  width: 100%;
}

.w-60 {
  width: 60%;
}
</style>
